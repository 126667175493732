import React from 'react'
import styled from 'styled-components'
import { Button, Heading, Flex } from '@pancakeswap/uikit'
import Page from 'components/Layout/Page'
import { useTranslation } from '@pancakeswap/localization'

const Container = styled.div`
    width:100%;
    max-width: 1300px;
    display:flex;
    justify-content:center;
    align-items:center;
    padding-top:1.5rem;
    padding-bottom:1.5rem;
    flex-direction: column;
`
const NotFound = () => {
  const { t } = useTranslation()

  return (
    <Page>
      <CsContainer>
        <Content>
          <Heading scale="xxl" color='white'>Page not Found</Heading>
          <CustomButton as="a" href="/">
            {t('GO BACK HOME')}
          </CustomButton>
        </Content>
        <ContainerImg/>
      </CsContainer>
    </Page>
  )
}

export default NotFound

const CsContainer = styled(Container)`
    position: relative;
`

const CustomButton = styled(Button)`
  width: 374px;
  height: 48px;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 90px;
  font-weight: 700;
  margin-top:2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.primaryBright};
  @media only screen and (max-width: 600px) {
     width: 96%;
  
}
`
const ContainerImg = styled(Flex)`
    width:100%;
    height: 595px;
    background-image:url("/images/base_img/bg_not_found.png");
    background-size:contain;
    background-repeat:no-repeat;
    background-position:center;
    @media only screen and (min-width: 768px) and (max-width: 1000px) {
      height: 400px;
    }
    @media only screen and (max-width: 600px) {
      height: 360px;
      width: 100%;
    }
`
const Content = styled(Flex)`
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 10%;
`